<template>
  <UInput
    v-model="dateInput"
    autofocus
    :placeholder="DEFAULT_DATE_FORMAT"
    class="w-full px-3 py-3 font-normal"
    @blur="onChangeDate($event.target.value)"
    @keydown.enter.prevent.stop="$event.target?.blur()"
  />

  <DatePicker
    :model-value="internalValue"
    @update:model-value="onChange"
  />
</template>

<script lang="ts" setup>
import { z } from 'zod'
import { DEFAULT_DATE_FORMAT } from '#core/constant'

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
  preview: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  format: {
    type: String,
    default: 'yyyy MMM dd',
  },
})
const dateSchema = z.coerce.date()

const emit = defineEmits<{
  (e: 'change', value: string): void
}>()

const internalValue = ref(dateFormat(props.value))
const dateInput = ref(dateFormat(props.value))

const onChange = (value: string) => {
  internalValue.value = dateFormat(value)
  dateInput.value = dateFormat(value)
  if (internalValue.value != props.value) {
    emit('change', internalValue.value)
  }
}

const onChangeDate = (value: string) => {
  const parsed = dateSchema.safeParse(value)
  if (parsed.success) {
    const partialValue = dateFormat(parsed.data)
    dateInput.value = partialValue ? partialValue : ''
    internalValue.value = dateInput.value
  }
}

watch(
  () => props.value,
  (val) => {
    internalValue.value = dateFormat(val)
    dateInput.value = dateFormat(val)
  }
)
</script>
