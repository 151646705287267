<template>
  <div class="relative h-full w-full">
    <DeferredPopover
      ref="popoverRef"
      :ui="{ wrapper: 'h-full' }"
      :disabled="disabled"
      v-bind="$attrs"
      :trigger-class="triggerClass"
      @update:open="$emit('update:open', $event)"
    >
      <template #content>
        <PreviewPopoverContentDate
          :value="value"
          :preview="preview"
          :disabled="disabled"
          :format="format"
          @change="$emit('change', $event)"
        />
      </template>
      <div class="flex items-center w-full h-full">
        <div :class="['pr-8 flex items-center ']">
          <UButton
            variant="ghost"
            :ui="{
              font: 'font-normal',
            }"
            :class="[
              {
                'text-gray-400': !props.value,
              },
              'bg-transparent text-black hover:bg-transparent text-xs px-2 btn outline-none shadow-none',
            ]"
            :disabled="disabled"
          >
            {{ dateFormat(props.value, format) }}
            <span
              v-if="!props.value"
              class="flex items-center gap-1 group-hover/cell:visible invisible"
            >
              -
            </span>
          </UButton>
        </div>
      </div>
    </DeferredPopover>
    <UButton
      v-if="props.value && !disabled"
      size="2xs"
      color="gray"
      variant="ghost"
      icon="i-heroicons-x-mark"
      class="absolute right-1 top-1/2 -translate-y-1/2 group-hover/cell:visible invisible z-20"
      @click="onDelete"
    />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  value: {
    type: String,
    required: true,
  },
  preview: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  format: {
    type: String,
    default: 'yyyy MMM dd',
  },
  triggerClass: {
    type: Function as PropType<(active: boolean) => string[]>,
    required: false,
  },
})

const emit = defineEmits<{
  (e: 'change', value: string): void
  (e: 'update:open', value: boolean): void
}>()

const onDelete = () => {
  emit('change', '')
}
</script>

<style scoped lang="scss">
.btn:focus-visible {
  outline: none;
  box-shadow: none;
}
</style>
