<template>
  <div v-if="preview">{{ dateFormat(value, format) || '-' }}</div>
  <PreviewEditableDate
    v-else
    v-model:open="open"
    v-bind="$props"
    :trigger-class="triggerClass"
    @change="onChange"
    @update:open="$emit('update:open', $event)"
  />
</template>

<script lang="ts" setup>
import type { TaskFieldRef } from '#field/types'

const { updateTaskCustomField } = useUpdateTask()

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  containerClass: {
    type: String,
    default: '',
  },
  taskId: {
    type: String,
    required: true,
  },
  fieldId: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
  preview: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  format: {
    type: String,
    default: 'yyyy MMM dd',
  },
  triggerClass: {
    type: Function as PropType<(active: boolean) => string[]>,
  },
})

const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
}>()

const open = ref(false)

const onChange = (value: string | null) => {
  // Update the value
  updateTaskCustomField({
    taskIds: [props.taskId],
    fieldId: props.fieldId,
    value,
    useSelectedTasks: true,
  })

  emit('update:open', false)
  open.value = false
}

defineExpose<TaskFieldRef>({
  open: () => {
    open.value = true
  },
  close: () => {
    open.value = false
  },
  delete: () => {
    onChange(null)
  },
})
</script>
